import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LeaveAnalytics } from '../../../utilities/types/types';
import Leave_API from '../../../api/leaveService';



// Define the type for your slice state
interface AnalyticState {
  analytics: LeaveAnalytics | null;
}

// Create an async thunk to fetch employee data
export const getLeaveAnalytics = createAsyncThunk('leave-analytics', async () => {
  try {
    const response = await Leave_API.LeaveAnalytics();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const analyticsSlice = createSlice({
  name: 'leave-analytics',
  initialState: {
    analytics: null,
  } as AnalyticState,
  reducers: {
    setLeaveAnalytics: (state, { payload }) => {
      state.analytics = payload;
    },
  },
 
});
export const { setLeaveAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;


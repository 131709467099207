import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LeaveId, LeaveValues } from '../../../utilities/types/types';
import Leave_API from '../../../api/leaveService';

interface OneLeaveState {
  oneLeave: LeaveValues;
}

export const getOneLeave = createAsyncThunk(
  'oneLeave',
  async (LeaveId: LeaveId) => {
    try {
      const response = await Leave_API.getOneLeave(LeaveId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const oneLeaveSlice = createSlice({
  name: 'oneLeave',
  initialState: {
    oneLeave: {} as LeaveValues,
  } as OneLeaveState,
  reducers: {
    setOneLeave: (state, { payload }) => {
      state.oneLeave = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneLeave.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(getOneLeave.fulfilled, (state, action) => {
        state.oneLeave = action.payload;
      })
      .addCase(getOneLeave.rejected, (state, action) => {
        // Handle rejected state if needed
      });
  },
});

export const { setOneLeave } = oneLeaveSlice.actions;

export default oneLeaveSlice.reducer;

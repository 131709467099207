import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Employee_API from '../../../api/employeeService';

interface UploadCSVState {
  uploadedData: FormData | null; // Updated type to FormData or null for reset flexibility
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: { responseData: any | null; errorMessage: string } | null;
}

const initialState: UploadCSVState = {
  uploadedData: null, // FormData initialized to null
  status: 'idle',
  error: null,
};

export const bulkEmployeesUpload = createAsyncThunk(
  'user/employeeUpload',
  async (data: FormData, thunkAPI) => {
    try {
      const response = await Employee_API.bulkUpload(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        responseData: error.response?.data || null,
        errorMessage: error.message,
      });
    }
  }
);

const employeeUploadSlice = createSlice({
  name: 'employeeUpload',
  initialState,
  reducers: {
    setUploadedData: (state, action) => {
      state.uploadedData = action.payload; // Assign FormData directly
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bulkEmployeesUpload.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(bulkEmployeesUpload.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null; // Reset error on success
      })
      .addCase(bulkEmployeesUpload.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as {
          responseData: any | null;
          errorMessage: string;
        };
      });
  },
});

export const { setUploadedData } = employeeUploadSlice.actions;

export default employeeUploadSlice.reducer;

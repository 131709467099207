import { Leave, LeaveId, SendMessageValues } from "../utilities/types/types";
import axiosService from "./axios/axios";
import uploadAxiosService from "./axios/uploadAxios";

export const API_URL = `${process.env.REACT_APP_API_BASE_URL}admin/leave/`;

export default class Leave_API {
  static async create(data: any): Promise<any> {
    return await axiosService({
      method: "POST",
      data: data,
      url: `${API_URL}create/leave-type`,
    });
  }
  static async getAllLeave(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}`,
    });
  }
  static async getOneLeave(LeaveId: LeaveId): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}${LeaveId}`,
    });
  }
  static async getApprovedLeave(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}`,
      params: {
        status: "approved",
      },
    });
  }

  static async getRequestLeave(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}`,
      params: {
        status: "request",
      },
    });
  }
  static async getRejectedLeave(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}`,
      params: {
        status: "rejected",
      },
    });
  }
  static async LeaveAnalytics(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}get/analytics`,
    });
  }
  static async LeaveType(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}/get/leave-type`,
    });
  }

  static async sendMessageLeave(
    LeaveId: LeaveId,
    data: SendMessageValues
  ): Promise<any> {
    return await axiosService({
      method: "PATCH",
      url: `${API_URL}leave/message/${LeaveId}`,
      data: data,
    });
  }

  static async approveLeave(LeaveId: LeaveId): Promise<any> {
    return await axiosService({
      method: "PATCH",
      url: `${API_URL}approve/${LeaveId}`,
    });
  }
  static async rejectLeave( LeaveId: LeaveId): Promise<any> {
    return await axiosService({
      method: "PATCH",
      url: `${API_URL}decline/${LeaveId}`,
    });
  }
  static async updateLeaveType(data: any, categoryId: string): Promise<any> {
    return await axiosService({
      method: "PATCH",
      data: data,
      url: `${API_URL}update/leave-type/${categoryId}`,
    });
  }
  static async deleteLeaveType(categoryId: string): Promise<any> {
    return await axiosService({
      method: "DELETE",
      url: `${API_URL}leave-type/delete/${categoryId}`,
    });
  }

  static async getLeaveActivity(LeaveId: LeaveId): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}get/activity/${LeaveId}`,
    });
  }
  static async getLeaveChart(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}get/count/category`,
    });
  }
}

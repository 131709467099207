import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import TitleSection from "../../../utilities/headers/titleSection";
import DisabledInput from "../../../utilities/components/disabledInput";
import Comms from "../../../utilities/components/coms";
import { sendLeaveMessageForm } from "../../../redux/slices/leave/sendLeaveMessage";
import {
  getLeaveActivities,
  setLeaveActivity,
} from "../../../redux/slices/leave/leaveActivities";
import { LeaveId } from "../../../utilities/types/types";
import { getOneLeave } from "../../../redux/slices/leave/oneLeave";
import { rejectLeave } from "../../../redux/slices/leave/rejectLeave";
import { approvedLeave } from "../../../redux/slices/leave/approveLeave";

function ApproveLeaveForm() {
  const [loading, setLoading] = useState(false);

  const { leaveId } = useParams<LeaveId>();
  const dispatch = useAppDispatch();
  const validLeave = leaveId || "";
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (validLeave) {
      dispatch(getOneLeave(validLeave));
      dispatch(getLeaveActivities(validLeave)).then((response) => {
        dispatch(setLeaveActivity(response.payload));
      });
    }
  }, [dispatch, validLeave]);

  const activity = useAppSelector((state) => state.leaveActivity.leaveActivity);
  const leave = useAppSelector((state) => state.oneLeave.oneLeave);

  const createdAtDate = new Date(leave.createdAt);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate.getTime() - createdAtDate.getTime();

  // Convert milliseconds to days
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  const getFormattedDate = (dateString: string | undefined) =>
    dateString ? new Date(dateString).toLocaleDateString() : "";

  const handleSubmit = async (editorHtml: string, leaveId: string) => {
    setLoading(true);
    try {
      await dispatch(
        sendLeaveMessageForm({ data: { sendMessage: editorHtml }, leaveId })
      ).unwrap();

      await dispatch(getLeaveActivities(leaveId)).unwrap();
    } catch (error) {
      console.error("Failed to submit or refetch activities:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleReject = async () => {
    if (validLeave) {
      setLoading(true);
      try {
        await dispatch(rejectLeave(validLeave)).unwrap();
        navigate(-1);
      } catch (error) {
        console.error("Failed to reject leave:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleApprove = async () => {
    if (validLeave) {
      setLoading(true);
      try {
        await dispatch(approvedLeave(validLeave)).unwrap();
        navigate(-1);
      } catch (error) {
        console.error("Failed to approve leave:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
          {/* <img src={loader} alt='' width={800} height={800} /> */}
        </div>
      )}
      <div>
        <TitleSection
          subtitle={`Last edit ${daysAgo} days ago`}
          type={
            leave.status
              ? leave.status.charAt(0).toUpperCase() + leave.status.slice(1)
              : ""
          }
          onRejectButtonClick={handleReject}
          onSaveButtonClick={handleApprove}
          greenButtonText="Approve"
          lightButtonText="Reject"
          onCancelButtonClick={handleCancelClick}
          grayButtonText="Cancel"
        />
      </div>
      <div className="text-greyDark">
        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Type"
            showDropdown
            value={leave?.leaveType || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
          />
          <DisabledInput
            label="Duration"
            value={leave?.duration || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
          />
        </div>

        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Start Date"
            showDropdown
            value={getFormattedDate(leave?.startDate)}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
          />
          <DisabledInput
            label="End Date"
            showDropdown
            value={getFormattedDate(leave?.endDate)}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
          />
        </div>
        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Backstopper"
            showDropdown
            value={leave?.backStop || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
          />
        </div>

        <DisabledInput
          label="Notes"
          value={leave?.notes || ""}
          className="w-full p-2 mb-5 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
      </div>
      <Comms
        validId={validLeave}
        activity={activity}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default ApproveLeaveForm;

import React from "react";
import { ReactSVG } from "react-svg";
import download from "../../../../asset/download.svg";

const SampleCSVDownload: React.FC = () => {
  const expectedDataFormat = [
    { name: "firstName", type: "string" },
    { name: "lastName", type: "string" },
    { name: "middleName", type: "string" },
    { name: "staffId", type: "number" },
    { name: "email", type: "string" },
    { name: "phone", type: "string" },
    { name: "dOB ", type: "date" },
    { name: "gender", type: "string" },
    { name: "department", type: "string" },
    { name: "jobTitle", type: "string" },
    { name: "unit", type: "string" },
    { name: "employmentType", type: "string" },
    { name: "employeeSupervisor", type: "string" },
    { name: "dateEmployed", type: "date" },
    { name: "hoursPerWeekMin", type: "string" },
    { name: "hoursPerWeekMax", type: "string" },
    { name: "payGrade", type: "string" },
    { name: "message", type: "string" },
  ];

  const downloadSampleCSV = () => {
    const csvContent =
      expectedDataFormat.map((header) => header.name).join(",") + "\n";
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="flex justify-center py-6">
      <button className="flex gap-2 text-center " onClick={downloadSampleCSV}>
        <ReactSVG src={download} />
        <p className="text-greenDark">Download Template here</p>
      </button>
    </div>
  );
};

export default SampleCSVDownload;

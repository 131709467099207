import DepartmentIndex from "../admin/employes/department";
import ExitIndex from "../admin/employes/exitManagement";
import ExitFormIndex from "../admin/employes/exitManagement/exitFormsIndex";
import OnboardingIndex from "../admin/employes/onboarding";
import BulkIndex from "../admin/employes/onboarding/bulkEmployees";
import EditOnboardingForm from "../admin/employes/onboarding/editEmployee/editEmployee";
import OnboardingForm from "../admin/employes/onboarding/onboardForm/newOnboarding/onboardIndex";
import ViewOnboardingneww from "../admin/employes/onboarding/viewEmployee/new/view";
import ExpenseIndex from "../admin/expenseManagement";
import ExpenseCategories from "../admin/expenseManagement/forms/expenseCategories";
import ExpenseCreate from "../admin/expenseManagement/forms/expenseCreate";
import ReiemburseExpense from "../admin/expenseManagement/forms/reiemburse";
import RequestExpenseForm from "../admin/expenseManagement/forms/request";
import ViewExpense from "../admin/expenseManagement/forms/viewExpense";
import LeaveIndex from "../admin/leaveManagement";
import ApproveLeaveForm from "../admin/leaveManagement/leave/approve";
import ViewLeaveForm from "../admin/leaveManagement/leave/viewLeave";
import LeaveConfig from "../admin/leaveManagement/leaveType";
import NewLeaveType from "../admin/leaveManagement/leaveType/createLeaveType";
import PayGradeIndex from "../admin/payroll/paygrades";
import NewPaygrade from "../admin/payroll/paygrades/newPaygrade";
import RunPayrollIndex from "../admin/payroll/runPayrol";
import PayrollIndex from "../admin/payroll/runPayrol/payrollTable";
import PayrollSettings from "../admin/payroll/settings";
import AdminSettingsIndex from "../admin/settings";
import AdminDashboard from "../pages/overview";
import ComingSoon from "../utilities/comingSoon";

export const adminRoutes = [
  {
    path: "dashboard",
    component: <AdminDashboard />,
  },
  {
    path: "onboarding",
    component: <OnboardingIndex />,
  },
  {
    path: "onboard",
    component: <OnboardingForm />,
  },

  {
    path: "Exit",
    component: <ExitIndex />,
  },
  {
    path: "exit-form/:employeeId",
    component: <ExitFormIndex />,
  },
  {
    path: "bulk-upload",
    component: <BulkIndex />,
  },
  {
    path: "expense",
    component: <ExpenseIndex />,
  },
  {
    path: "expense-category",
    component: <ExpenseCategories />,
  },
  {
    path: "run-payroll",
    component: <RunPayrollIndex />,
  },
  {
    path: "payroll",
    component: <PayrollIndex />,
  },
  {
    path: "paygrades",
    component: <PayGradeIndex />,
  },
  {
    path: "payroll-settings",
    component: <PayrollSettings />,
  },
  {
    path: "new-expense",
    component: <ExpenseCreate />,
  },
  {
    path: "view-expense/:expenseId",
    component: <ViewExpense />,
  },
  // {
  //   path: 'edit-expense/:expenseId',
  //   component: <EditExpense />,
  // },
  {
    path: "view-expense/:employeeId",
    component: <ViewExpense />,
  },

  {
    path: "new-paygrade",
    component: <NewPaygrade />,
  },
  {
    path: "Departments",
    component: <DepartmentIndex />,
  },
  {
    path: "edit-employee/:employeeId",
    component: <EditOnboardingForm />,
  },
  {
    path: "view-employee/:employeeId",
    component: <ViewOnboardingneww />,
  },
  {
    path: "request-expense/:expenseId",
    component: <RequestExpenseForm />,
  },
  {
    path: "reiemburse-expense/:expenseId",
    component: <ReiemburseExpense />,
  },
  {
    path: "leave-management",
    component: <LeaveIndex />,
  },
  {
    path: "leave-config",
    component: <LeaveConfig />,
  },
  {
    path: "view-leave/:leaveId",
    component: <ViewLeaveForm />,
  },
  {
    path: "approve-leave/:leaveId",
    component: <ApproveLeaveForm />,
  },
  // {
  //   path: "new-leave-type",
  //   component: <NewLeaveType />,
  // },
  {
    path: "settings",
    component: <AdminSettingsIndex />,
  },
  {
    path: "unsubscribe",
    component: <ComingSoon />,
  },
];

import React, { useState } from "react";
import * as Yup from "yup";
import "../../styles/auth.css";
import { Formik, FormikHelpers } from "formik";
import CustomInput from "../utilities/forms/customInput";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch } from "../redux/hook/authHook";
import { signup } from "../redux/slices/auth/authSlice";
import { Bars } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";
import { SignUpTypes } from "../utilities/types/types";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone Number is required"),
  companySize: Yup.number().required("Company Size is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Confirm Password does not match"),
});

const SignUpForm: React.FC = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    companySize: 0,
    captcha: "",
  };
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const handleSubmit = async (
    data: SignUpTypes,
    formikBag: FormikHelpers<SignUpTypes>
  ) => {
    setLoading(true);
    try {
      const res = await dispatch(signup(data));

      if (res.payload.statusCode === 201) {
        toast.success("Account created succesfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
        setLoading(false);
        formikBag.resetForm({ values: initialValues });
      } else if (res.payload.statusCode === 400) {
        toast.error("Your Email is already in use", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
        setLoading(false);
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: " #D92D20", color: "white" },
      });
      setLoading(false);
    }
  };
  return (
    <>
      <div className="registerForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className="formGroup">
                <CustomInput
                  id="firstName"
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.errors.firstName}
                  className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[400px]"
                  wrapperClassName=""
                  placeholder="Enter First Name"
                />
              </div>
              <div className="formGroup">
                <CustomInput
                  id="lastName"
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.errors.lastName}
                  className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[400px]"
                  wrapperClassName=""
                  placeholder="Enter Last Name"
                />
              </div>

              <div className="formGroup">
                <CustomInput
                  id="email"
                  label="Professional Email"
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[400px]"
                  wrapperClassName=""
                  placeholder="Enter Professional Email"
                />
              </div>
              <div className="formGroup">
                <CustomInput
                  id="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={formik.errors.phoneNumber}
                  className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[400px]"
                  wrapperClassName=""
                  placeholder="Enter Phone Number"
                />
              </div>
              {/* <div className="formGroup">
                <CustomInput
                  id="companyName"
                  label="Company Name"
                  type="text"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.errors.companyName}
                  className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[400px]"
                  wrapperClassName=""
                  placeholder="Enter Company Name"
                />
              </div> */}
              <div className="formGroup">
                <CustomInput
                  id="companySize"
                  label="Company Size"
                  type="text"
                  name="companySize"
                  value={formik.values.companySize}
                  onChange={formik.handleChange}
                  error={formik.errors.companySize}
                  className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[400px]"
                  wrapperClassName=""
                  placeholder="Enter Company Size"
                />
              </div>

              <div className="formGroup">
                <div className="">
                  <CustomInput
                    id="password"
                    label="Password"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.errors.password}
                    className="border-transparent  rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[350px]"
                    wrapperClassName="inputWrapper"
                    placeholder="Enter Password"
                  />
                </div>
              </div>

              <div className="formGroup">
                <div className="">
                  <CustomInput
                    id="confirmPassword"
                    label=" Re-EnterPassword"
                    type="password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.errors.confirmPassword}
                    className="border-transparent  rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[350px]"
                    wrapperClassName="inputWrapper"
                    placeholder="Re-enter Password"
                  />
                </div>
              </div>
              <div className="formGroup">
                <ReCAPTCHA
                  sitekey={siteKey}
                  onChange={(value) => {
                    formik.setFieldValue("captcha", value || "");
                  }}
                  theme="light"
                />
                {formik.touched.captcha && formik.errors.captcha && (
                  <div className="text-red-500">{formik.errors.captcha}</div>
                )}
              </div>
              <div className="formGroup">
                <button
                  type="submit"
                  className={`font-md text-white text-[.9rem] py-2 rounded-lg my-6 w-[100%] ${
                    loading
                      ? "bg-[#9FE6E8] px-44 lg:px-12 flex justify-center items-center"
                      : !formik.isValid
                      ? "bg-[#9FE6E8] px-28 lg:px-12"
                      : "bg-greenDark px-28 lg:px-12"
                  }`}
                  disabled={
                    !formik.values.captcha || loading || !formik.isValid
                  }
                >
                  {loading ? (
                    <div className="pl-32">
                      <Bars
                        height={20}
                        width={20}
                        color="#fff"
                        ariaLabel="bars-loading"
                      />
                    </div>
                  ) : (
                    "CREATE AN ACCOUNT"
                  )}
                </button>

                <div className="p-4 pt-2 lg:p-1 lg:text-sm">
                  <p>
                    By signing up, you&apos;re agreeing with Quiickops&apos;s{" "}
                    <br />
                    <span className="textColor">
                      Terms and Condition Agreement and Privacy Policy.
                    </span>
                  </p>
                </div>
                {/* <div className='lineDiv'> */}
                {/* <div className='lineLeft'></div>
                  <p>OR</p>
                  <div className='lineRight'></div>
                </div>
                <button className='btnMod'>SIGN UP WITH GOOGLE</button> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SignUpForm;

import { useEffect, useState } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import add from "../../../asset/add.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { FormikHelpers } from "formik";
import {
  getLeaveType,
  setLeaveType,
} from "../../../redux/slices/leave/leaveType";
import LeaveType from "../tables/leaveTypeTable";
import Tabs from "../../../utilities/tabs";
import NewLeaveType from "./createLeaveType";

function LeaveConfig() {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(getLeaveType());
  };
  const handleCancel = () => {
    handleModalClose();
  };
  useEffect(() => {
    dispatch(getLeaveType()).then((response) => {
      dispatch(setLeaveType(response.payload));
    });
  }, [dispatch]);

  const leave = useAppSelector((state) => state.leaveType.leaveType);
  const rowCount = leave?.length;

  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  return (
    <div>
      <TitleSection title="Leave Configuration" />
      <div className="">
        <div className="py-8">
          <Tabs
            tabs={["Custom Leave"]}
            handleTabClick={handleTabClick}
            activeTab={activeTab}
            activeClassName="bg-[#E0F6F6] border-b text-center px-4 py-2"
            inactiveClassName="px-4 py-2"
          />
        </div>
        <div className="tab-content">
          {activeTab === 1 && (
            <>
              <TitleSection
                title="Custom Leave"
                subtitle="You can new leave categories using create custom leave types."
                greenButtonText="Create Custom Leave"
                greenButtonIcon={add}
                showModal
                size="xl h-auto"
                closeModal={handleCancel}
                externalIsModalOpen={isModalOpen}
                externalSetIsModalOpen={setIsModalOpen}
              >
                <NewLeaveType
                  handleCancel={handleCancel}
                  onClose={handleModalClose}
                />
              </TitleSection>
              <LeaveType leaveCategories={leave} rowCount={rowCount} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaveConfig;

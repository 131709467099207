
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/authSlice';
import onboardingFormSlice from './slices/auth/onboardingSlic';
import activeEmployees from './slices/employes/activeEmployees';
import analyticsSlice from './slices/employes/analyticsSlice';
import archiveEmployees from './slices/employes/archiveEmployees';
import employeeReducer  from './slices/employes/employesSlice';
import getAllEmployees  from './slices/employes/employesSlice';
import adminDetails from './slices/auth/adminDetails';
import bulkUpload from './slices/employes/bulkUpload';
import archivedEmployeeForm from './slices/auth/archivedEmployeeForm';
import allExpenses from './slices/expenses/allExpenses';
import approveExpense from './slices/expenses/approveExpense';
import expenseAnalytics from './slices/expenses/expenseAnalytics';
import requestExpense from './slices/expenses/requestExpense';
import getOneExpense from './slices/expenses/getOneExpense';
import getCompanyExpense from './slices/expenses/companyExpense';
import getOneEmployee from './slices/employes/getOneEmployee';
import editExpenses from './slices/expenses/editExpenses';
import getGeneralSettings  from './slices/payroll/getGeneral';
import editOnboarding from './slices/auth/editOnboarding';
import  getPayrollData  from './slices/payroll/runPayrollData';
import  CustomEarnings from './slices/payroll/customEarning';
import getDefaultCategory  from './slices/expenses/defaultCategories';
import getCustomCategory  from './slices/expenses/customCategories';
import  paygradesSelector from './slices/paygrade/getPaygrade';
import getPaygradeData from './slices/paygrade/getPaygradeTable';
import  getActivities  from './slices/expenses/activity';
import  getAllDepartments  from './slices/auth/getAllDept';
import getAllCategories from './slices/expenses/allCategories';
import organisationAuthslice from './slices/auth/organisationAuthslice';
import getAllEmployeesCount  from './slices/auth/dashbordChart';
import  getAllExpenseCategory  from './slices/expenses/expenseChart';
import getPayschedule from './slices/payroll/getPayschedule';
import getEmployeesPaidExpense from './slices/expenses/employeeExpenses';
import getAllLeave  from './slices/leave/allLeave';
import  getLeaveType  from './slices/leave/leaveType';
import getLeaveAnalytics from './slices/leave/analytics';
import  getLeaveActivities  from './slices/leave/leaveActivities';
import  getOneLeave  from './slices/leave/oneLeave';
import getLegalEntity  from './slices/service/legalEntities';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    org: organisationAuthslice,
    onboardingForm: onboardingFormSlice,
    employee: employeeReducer,
    analytics: analyticsSlice,
    active: activeEmployees,
    archived: archiveEmployees,
    admin: adminDetails,
    bulkUpload: bulkUpload,
    archivedForm: archivedEmployeeForm,
    allExpenses: allExpenses,
    approvedExpenses: approveExpense,
    requestedExpenses: requestExpense,
    expenseAnalytics: expenseAnalytics,
    editExpensesForm: editExpenses,
    oneExpense: getOneExpense,
    oneEmployee: getOneEmployee,
    defaultCategory: getDefaultCategory,
    customCategory:getCustomCategory,
    editOnboardingForm: editOnboarding,
    runPayroll: getPayrollData,
    generalSettings: getGeneralSettings,
    customEarnings: CustomEarnings,
    paygrades:paygradesSelector,
    paygradeTable:getPaygradeData,
    activity: getActivities,
    department: getAllDepartments,
    allCategories:getAllCategories,
    allEmployees:getAllEmployees,
    employeeCount:getAllEmployeesCount,
    categoriesData:getAllExpenseCategory,
    payschedulData:getPayschedule,
    expenseCategory:getAllExpenseCategory,
    companyExpense: getCompanyExpense,
   employeeExpense: getEmployeesPaidExpense,
   allLeave:getAllLeave,
   leaveType: getLeaveType,
   leaveAnalytics: getLeaveAnalytics,
   leaveActivity: getLeaveActivities,
   oneLeave: getOneLeave,
   country:getLegalEntity,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { Admin, Passwords } from "../../utilities/types/types";
import CustomInput from "../../utilities/forms/customInput";
import { Button } from "../../utilities/button";
import { passwordUpdate } from "../../redux/slices/auth/accountInfo";
import { useAppDispatch } from "../../redux/hook/authHook";
import { toast } from "react-toastify";
import loader from "../../asset/images/loader 2.gif";

import BioProfile from "./bioProfile";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmNewPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("confirmPassword")], "Confirm Password does not match"),
});

interface AccountInfoFormProps {
  admin: Admin | null;
}

const AccountInfoForm = ({ admin }: AccountInfoFormProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async (data: Passwords) => {
    setLoading(true);

    try {
      const response = await dispatch(passwordUpdate(data));
      if (response?.payload?.statusCode === 400) {
        setLoading(false);

        toast.error("Current Password does not match", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (response?.payload?.statusCode === 200) {
        setLoading(false);
        toast.success("Password Updated successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    }
  };

  const initialValues: Passwords = {
    currentPassword: "",
    confirmNewPassword: "",
    confirmPassword: "",
    // remember: false,
  };
  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center bg-black/40 justify-center">
          <img src={loader} alt="" width={800} height={800} />
        </div>
      )}
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <hr className="my-7"></hr>
              <p className="pb-6 text-lg font-medium">Change Password</p>
              <div className="flex gap-6">
                <CustomInput
                  id="currentPassword"
                  label="Current Password"
                  type="text"
                  name="currentPassword"
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  error={formik.errors.currentPassword}
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
                  wrapperClassName=""
                  placeholder="Enter Password"
                />
                <CustomInput
                  id="confirmPassword"
                  label="New Password"
                  type="text"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.errors.confirmPassword}
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
                  wrapperClassName=""
                  placeholder="Enter Password"
                />
              </div>
              <div className="flex ">
                <CustomInput
                  id="confirmNewPassword"
                  label="Confirm New Password"
                  type="text"
                  name="confirmNewPassword"
                  value={formik.values.confirmNewPassword}
                  onChange={formik.handleChange}
                  error={formik.errors.confirmNewPassword}
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
                  wrapperClassName=""
                  placeholder="Enter Password"
                />
              </div>
              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
                >
                  Save changes
                </Button>
              </div>
            </form>
          )}
        </Formik>

        {/* Loader */}
        {loading && (
          <div className="loaderContainer">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountInfoForm;

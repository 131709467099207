import axiosService from "./axios/axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

export default class Service_API {
  static async getAllLegalEntity(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}country/get/country-code`,
    });
  }
}

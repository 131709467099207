import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  LeaveId } from '../../../utilities/types/types';
import leave_API from '../../../api/leaveService';
import Leave_API from '../../../api/leaveService';

export const approvedLeave = createAsyncThunk(
  'approved/leave',
  async (
     leaveId: LeaveId ,
    thunkAPI
  ) => {
    try {
      const response = await Leave_API.approveLeave( leaveId);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const approvedLeaveSlice = createSlice({
  name: 'approved/leave',
  initialState: {
    formData: {
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
     
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } =
  approvedLeaveSlice.actions;

export default approvedLeaveSlice.reducer;
export type RootState = ReturnType<typeof approvedLeaveSlice.reducer>;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Auth_API from "../../../api/authService/authservice";
import { Admin } from "../../../utilities/types/types";

export const dataUpdate = createAsyncThunk("dataUpdate", async (data: any, thunkAPI) => {
  try {
    const response = await Auth_API.updateData(data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const dataSlice = createSlice({
  name: "data",
  initialState: {
    legalEntity:"",
    fullName: "",
    email: "",
    phone: "",
  

    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetForm: state => {
      return {
        ...state,
       
        fullName: "",
        email: "",
        phone: "",
        legalEntity:"",

        error: null,
      };
    },
  },
});
// Export actions
export const { setFormData, resetForm } = dataSlice.actions;

export default dataSlice.reducer;
export type RootState = ReturnType<typeof dataSlice.reducer>;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LeaveId } from "../../../utilities/types/types";
import leave_API from "../../../api/leaveService";
import Leave_API from "../../../api/leaveService";

export const rejectLeave = createAsyncThunk(
  "reject/leave",
  async (leaveId: LeaveId, thunkAPI) => {
    try {
      const response = await Leave_API.rejectLeave(leaveId);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const rejectLeaveSlice = createSlice({
  name: "reject/leave",
  initialState: {
    formData: {},
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {};
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } = rejectLeaveSlice.actions;

export default rejectLeaveSlice.reducer;
export type RootState = ReturnType<typeof rejectLeaveSlice.reducer>;

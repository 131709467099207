import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Auth_API from "../../../api/authService/authservice";
import { Admin } from "../../../utilities/types/types";



interface AdminState {
  admin: Admin | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

// Async thunk to fetch the admin's name and role
export const getAdminInfo = createAsyncThunk("admin/getAdminInfo", async () => {
  try {
    const response = await Auth_API.admin();
    return response.data;
  } catch (error) {
    throw error;
  }
});

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    admin: null,
    loading: "idle",
    error: null,
  } as AdminState,
  reducers: {
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAdminInfo.pending, state => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getAdminInfo.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.admin = action.payload;
      })
      .addCase(getAdminInfo.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "An error occurred while fetching user information.";
      });
  },
});

export default adminSlice.reducer;

export const { setAdmin } = adminSlice.actions;

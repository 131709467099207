import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomInput from "../../utilities/forms/customInput";
import ImageUpload from "../../utilities/forms/customImageUpload";
import { Button } from "../../utilities/button";
import { Admin } from "../../utilities/types/types";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import { toast } from "react-toastify";
import { dataUpdate } from "../../redux/slices/auth/dataUpdate";
import {
  getLegalEntity,
  setlegalEntity,
} from "../../redux/slices/service/legalEntities";

interface AccountInfoFormProps {
  admin: Admin | null;
}

const BioProfile = ({ admin }: AccountInfoFormProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    companyName: admin?.companyName || "",
    quickoppsLink: admin?.quiickopsUrl || "",
    firstName: admin?.firstName || "",
    lastName: admin?.lastName || "",
    fullName: admin?.fullName || "",
    legalEntityLocation: admin?.legalEntityLocation || "",
    email: admin?.email || "",
    phone: admin?.phone || "",
  });

  useEffect(() => {
    setInitialValues({
      companyName: admin?.companyName || "",
      quickoppsLink: admin?.quiickopsUrl || "",
      fullName: admin?.fullName || "",
      firstName: admin?.firstName || "",
      lastName: admin?.lastName || "",
      email: admin?.email || "",
      legalEntityLocation: admin?.legalEntityLocation || "",
      phone: admin?.phone || "",
    });
  }, [admin]);
  useEffect(() => {
    dispatch(getLegalEntity()).then((response) => {
      dispatch(setlegalEntity(response.payload));
    });
  }, [dispatch]);
  const country = useAppSelector((state) => state.country.legalEntity);
  console.log(country);
  const handleSubmit = async (values: typeof initialValues) => {
    console.log({ values });
    setLoading(true);
    try {
      const response = await dispatch(dataUpdate(values));
      console.log({ response });
      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success("Profile updated successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      } else {
        toast.error("Update failed", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <div className="">
        <ImageUpload
          circle={<p>Image Here</p>}
          firstName={admin?.firstName}
          lastName={admin?.lastName}
          image={admin?.profileImage}
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-6">
          <CustomInput
            id="companyName"
            label="Company Name"
            disabled={true}
            type="text"
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={formik.errors.companyName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Company Name"
          />
          <CustomInput
            id="quickoppsLink"
            label="Your Quiickops URL"
            disabled={true}
            type="text"
            name="quickoppsLink"
            value={formik.values.quickoppsLink}
            onChange={formik.handleChange}
            error={formik.errors.quickoppsLink}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="plum.quiickops.com"
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="firstName"
            label="First Name"
            type="text"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.errors.firstName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Full Name"
          />
          <CustomInput
            id="lastName"
            label="Last Name"
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.errors.lastName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Full Name"
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="email"
            label="Professional Email Address"
            type="text"
            name="email"
            disabled={true}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Professional Email Address"
          />
          <CustomInput
            id="phone"
            label="Phone Number"
            type="tel"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.errors.phone}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Phone Number"
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="legalEntity"
            label="Legal Entity"
            type="select"
            selectOptions={country.map((entity: any) => ({
              label: entity.name,
              value: entity.code,
            }))}
            name="legalEntityLocation"
            value={formik.values.legalEntityLocation}
            onChange={formik.handleChange}
            error={formik.errors.legalEntityLocation}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
          />
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            className="border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
          >
            Save changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BioProfile;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Leave_API from '../../../api/leaveService';
import { LeaveId, LeaveType, activityData } from '../../../utilities/types/types';

// Define the type for your slice state
interface LeaveActivityState {
  leaveActivity: activityData[];
}

// Create an async thunk to fetch employee data
export const getLeaveActivities = createAsyncThunk(
  'LeaveActivity',
  async (leaveId: LeaveId) => {
    try {
      const response = await Leave_API.getLeaveActivity(leaveId);
      return response.messages;
   
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const LeaveActivitySlice = createSlice({
  name: 'LeaveActivity',
  initialState: {
    leaveActivity: [],
  } as LeaveActivityState,
  reducers: {
    setLeaveActivity: (state, { payload }) => {
      state.leaveActivity = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(LeaveActivity.pending, (state) => {
  //       state.status = "loading";
  //       state.error = nupproved;
  //     })
  //     .addCase(approvedEmployees.fulfipproveded,
  //     .addCase(approvedEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
})
export const { setLeaveActivity } = LeaveActivitySlice.actions;

export default LeaveActivitySlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import leave_API from '../../../api/leaveService';
import Leave_API from '../../../api/leaveService';
import { LeaveId, SendMessageValues } from '../../../utilities/types/types';

export const sendLeaveMessageForm = createAsyncThunk(
  'sendLeaveMessage',
  async (
    { data, leaveId }: { data: SendMessageValues; leaveId: LeaveId },
    thunkAPI
  ) => {
    try {
      const response = await Leave_API.sendMessageLeave(leaveId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const sendLeaveMessageSlice = createSlice({
  name: 'sendLeaveMessage',
  initialState: {
    sendMessage: '',
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendLeaveMessageForm.fulfilled, (state, action) => {
      // Handle successful message sending
      state.sendMessage = action.payload;
      state.error = null;
    });
    // builder.addCase(sendMessageForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = sendLeaveMessageSlice.actions;

export default sendLeaveMessageSlice.reducer;
export type RootState = ReturnType<typeof sendLeaveMessageSlice.reducer>;

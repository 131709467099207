import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { LeaveType, OptionType } from "../../../utilities/types/types";
import { Button } from "../../../utilities/button";
import { createNewLeaveType } from "../../../redux/slices/leave/createLeaveType";
import TitleSection from "../../../utilities/headers/titleSection";
import CustomSelect from "../../../utilities/select";
import CustomInput from "../../../utilities/forms/customInput";
import {
  getAllDepartments,
  setAllDepartments,
} from "../../../redux/slices/auth/getAllDept";

const validationSchema = Yup.object({
  leaveName: Yup.string().required("Leave name is required"),
  acceptableDuration: Yup.string().required("Acceptable duration is required"),
  blackoutPeriod: Yup.array()
    .of(Yup.string())
    .required("Blackout period list is required"),
  leavePaid: Yup.string().required("Leave paid is required"),
  blackoutApplicable: Yup.string().required("Blackout applicable is required"),
  exception: Yup.array()
    .of(Yup.string())
    .when("blackoutApplicable", {
      is: "no",
      then: (schema) => schema.required("Exception list is required"),
      otherwise: (schema) => schema,
    }),
});
interface NewLeaveTypeProps {
  handleCancel: () => void;
  onClose?: () => void;
}
function NewLeaveType({ handleCancel, onClose }: NewLeaveTypeProps) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllDepartments()).then((response) => {
      dispatch(setAllDepartments(response.payload));
    });
  }, [dispatch]);

  const departments = useAppSelector((state) => state.department.allDepartment);

  const departmentOptions: OptionType[] = departments.map((dept) => ({
    value: dept.department._id,
    label: dept.department.departmentName,
  }));

  const months: OptionType[] = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];

  const initialValues: LeaveType = {
    leaveName: "",
    acceptableDuration: "",
    blackoutPeriod: [],
    leavePaid: "",
    blackoutApplicable: "",
    exception: [],
  };

  const handleSubmit = async (data: LeaveType) => {
    setLoading(true);
    try {
      const res = await dispatch(createNewLeaveType(data));
      setLoading(false);
      if (res?.payload?.error) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
      } else {
        toast.success("Leave created successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
        if (onClose) onClose();
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40"></div>
      )}
      <div>
        <TitleSection
          title="Create Leave Type"
          subtitle="Fill out required information and save."
        />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className=" ">
              <CustomInput
                id="leaveName"
                label="Leave Name"
                type="text"
                name="leaveName"
                value={formik.values.leaveName}
                onChange={formik.handleChange}
                error={formik.errors.leaveName}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
                placeholder="Enter leave name"
              />
              <CustomInput
                id="acceptableDuration"
                label="Acceptable Duration"
                type="text"
                name="acceptableDuration"
                value={formik.values.acceptableDuration}
                onChange={formik.handleChange}
                error={formik.errors.acceptableDuration}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
                placeholder="Enter duration"
              />
            </div>
            <div className=" ">
              <CustomSelect
                label="Blackout Period"
                name="blackoutPeriod"
                options={months}
                wrapperClassName="pt-[2px]"
                isMulti={true}
                className="focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
                onChange={(selectedValues: any) => {
                  formik.setFieldValue("blackoutPeriod", selectedValues);
                }}
              />
              <div className="mt-4 mb-2">
                <p className="mb-2">Is this leave paid?</p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      id="yes"
                      name="leavePaid"
                      value="yes"
                      checked={formik.values.leavePaid === "yes"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-4 h-4 mr-3 border rounded-full appearance-none border-greyTint checked:bg-greenDark checked:ring-greenDark focus:outline-none focus:border-greenDark focus:ring focus:ring-greenDark ring-offset-4"
                    />
                    Yes
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      id="no"
                      name="leavePaid"
                      value="no"
                      checked={formik.values.leavePaid === "no"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-4 h-4 mr-3 border rounded-full appearance-none border-greyTint checked:bg-greenDark checked:ring-greenDark focus:outline-none focus:border-greenDark focus:ring focus:ring-greenDark ring-offset-4"
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="mt-4 mb-2">
                <p className="mb-2">
                  Is the blackout period applicable to all departments?
                </p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      id="yes"
                      name="blackoutApplicable"
                      value="yes"
                      checked={formik.values.blackoutApplicable === "yes"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-4 h-4 mr-3 border rounded-full appearance-none border-greyTint checked:bg-greenDark checked:ring-greenDark focus:outline-none focus:border-greenDark focus:ring focus:ring-greenDark ring-offset-4"
                    />
                    Yes
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      id="no"
                      name="blackoutApplicable"
                      value="no"
                      checked={formik.values.blackoutApplicable === "no"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-4 h-4 mr-3 border rounded-full appearance-none border-greyTint checked:bg-greenDark checked:ring-greenDark focus:outline-none focus:border-greenDark focus:ring focus:ring-greenDark ring-offset-4"
                    />
                    No
                  </label>
                </div>
              </div>
              <div className="">
                <CustomSelect
                  label="If no, select exception(s)"
                  name="exception"
                  options={departmentOptions}
                  onChange={(selectedValues: any) => {
                    formik.setFieldValue("exception", selectedValues);
                  }}
                  isMulti={true}
                  disabled={formik.values.blackoutApplicable === "yes"}
                  className="focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
                />
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                type="submit"
                className="border text-white w-full border-gray-400 rounded-md p-2 bg-[#00989B] focus:outline-none focus:ring-2 focus:ring-gray-500  mt-10"
              >
                Save
              </Button>
              <Button
                type="button"
                className="border text-grayText w-full border-greyFade  rounded-md p-2 mt-10 flex justify-center"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default NewLeaveType;

import React, { useEffect, useState } from "react";
import Tabs from "../../utilities/tabs";
import TitleSection from "../../utilities/headers/titleSection";
import AccountInfoForm from "./accountInfo";
import ColorForm from "./companyStyling";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import { getAdminInfo, setAdmin } from "../../redux/slices/auth/adminDetails";
import BioProfile from "./bioProfile";

function AdminSettingsIndex() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const dispatch = useAppDispatch();

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAdminInfo());
        dispatch(setAdmin(response.payload));
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [dispatch]);

  const admin = useAppSelector((state) => state.admin.admin);

  return (
    <div>
      <TitleSection
        title="Settings"
        subtitle="Manage your team and preferences here."
        greenBtnLink=""
        grayBtnLink=""
        greenButtonIcon=""
        grayButtonIcon=""
      />
      <hr className="text-[#EAECF0] pb-6" />
      <Tabs
        tabs={[
          "Account Info",
          "Company Stylings",
          "Access Levels",
          "Billing",
          "2 step Auth",
          "API Management",
        ]}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        activeClassName="active-tab"
        inactiveClassName="inactive-tab"
      />
      <hr className="text-[#EAECF0] pt-6" />

      <div>
        {activeTab === 1 && (
          <>
            <h3 className="py-3 text-lg font-medium">Account Info</h3>
            <BioProfile admin={admin} />
            <AccountInfoForm admin={admin} />
          </>
        )}
        {activeTab === 2 && <ColorForm admin={admin} />}
      </div>
    </div>
  );
}

export default AdminSettingsIndex;

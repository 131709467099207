import React, { useMemo } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { Expense } from "../../../utilities/types/types";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import RowRange from "../../../utilities/customTable/rowsCount";
import GlobalFilter from "../../../utilities/customTable/globalFilter";
import Pagination from "../../../utilities/customTable/pagination";

type expenseTableProps = {
  expense: Expense[];
  rowCount: number;
};
const predefinedColors = [
  "#3498db",
  "#e74c3c",
  "#2ecc71",
  "#f39c12",
  "#9b59b6",
  "#34495e",
  "#1abc9c",
  "#e67e22",
  "#7f8c8d",
  "#c0392b",
];
function ReimburseExpenses({ expense, rowCount }: expenseTableProps) {
  const data = useMemo(() => {
    return expense.map((expense, index) => ({
      employeeName: expense.employeeName,
      description: expense.description,
      expenseDate: expense.expenseDate,
      paidBy: expense.paidBy,
      amount: expense.amount,
      attachment: expense.attachment,
      status: expense.status,
      file: expense.file,
      color: predefinedColors[index % predefinedColors.length],

      _id: expense._id,
    }));
  }, [expense]);

  const columns: Column<Expense>[] = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Expense Date",
        accessor: (row) => new Date(row.expenseDate).toLocaleDateString(),
      },
      {
        Header: "Paid By",
        accessor: "paidBy",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: (
          <div>
            Attachment
            <i className="fas fa-calendar" style={{ marginLeft: "5px" }}></i>
          </div>
        ),
        accessor: "file",
        Cell: ({ cell: { value } }) => (
          <div>{value ? (Array.isArray(value) ? value.length : 0) : 0}</div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <div>
            <p
              style={{
                backgroundColor:
                  value === "Review"
                    ? "green"
                    : value === "approved"
                    ? "#ECFDF3"
                    : value === "request"
                    ? "#FFF6ED"
                    : "#EFF8FF",
                borderColor: "transparent",
                textAlign: "center",
                width: "105px",
                padding: "0px 0px 0px 8px ",
                borderRadius: "16px",
                color:
                  value === "request"
                    ? "#C4320A"
                    : value === "review"
                    ? "green"
                    : value === "approved"
                    ? "#027A48"
                    : "#175CD3",
              }}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </p>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<Expense>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page index and page size
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div className="">
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText ">Reimbursed Expense</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4 ">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        {/* <ButtonIconLink
          className='pt-[10px] pb-[7px] px-4 item-center'
          src={filter}
          link='#'
          text='Filters'
        /> */}
      </div>

      {data.length === 0 ? (
        <EmptyState
          title="No expense data found"
          subtitle=""
          message="Kindly “Create Expenses” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default ReimburseExpenses;

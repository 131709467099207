import React, { useEffect, useState } from "react";
import Tabs from "../../utilities/tabs";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import { getAllLeave, setAllLeave } from "../../redux/slices/leave/allLeave";
import AllLeave from "./tables/allLeave";
import RequestLeave from "./tables/requestLeaveTable";
import ApprovedLeave from "./tables/approveLeave";
import RejectLeave from "./tables/rejectedLeave";
import TitleSection from "../../utilities/headers/titleSection";
import {
  getLeaveAnalytics,
  setLeaveAnalytics,
} from "../../redux/slices/leave/analytics";
import TaskValidation from "./taskValidation";

function LeaveIndex() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllLeave()).then((response) => {
      dispatch(setAllLeave(response.payload));
    });
    dispatch(getLeaveAnalytics()).then((response) => {
      dispatch(setLeaveAnalytics(response.payload));
    });
  }, [dispatch]);

  const Analytics = useAppSelector((state) => state.leaveAnalytics.analytics);
  const allLeave = useAppSelector((state) => state.allLeave.allLeave);
  const approvedLeave = allLeave.filter((leave) => leave.status === "approved");
  const rejectedLeave = allLeave.filter((leave) => leave.status === "rejected");
  const requestLeave = allLeave.filter((leave) => leave.status === "pending");
  const allRowCount = allLeave.length;
  const approvedRowCount = approvedLeave.length;
  const rejectedRowCount = rejectedLeave.length;
  const requestedRowCount = requestLeave.length;

  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };
  return (
    <div>
      <div>
        <TitleSection title="Leave Management" />
      </div>

      <div className=" ">
        <TaskValidation data={Analytics} />
      </div>
      <div className="">
        <div className="py-8">
          <Tabs
            tabs={["All Leave", "Request", "Approved", "Rejected"]}
            handleTabClick={handleTabClick}
            activeTab={activeTab}
            activeClassName="bg-[#E0F6F6] border-b text-center px-4 py-2"
            inactiveClassName="px-4 py-2"
          />
        </div>
        <div className="tab-content">
          {activeTab === 1 && (
            <AllLeave leave={allLeave} rowCount={allRowCount} />
          )}
          {activeTab === 2 && (
            <RequestLeave leave={requestLeave} rowCount={requestedRowCount} />
          )}
          {activeTab === 3 && (
            <ApprovedLeave leave={approvedLeave} rowCount={approvedRowCount} />
          )}
          {activeTab === 4 && (
            <RejectLeave leave={rejectedLeave} rowCount={rejectedRowCount} />
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaveIndex;

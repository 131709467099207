import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormStepValues } from "../../../../../utilities/types/types";
import { useAppDispatch } from "../../../../../redux/hook/authHook";
import CustomInput from "../../../../../utilities/forms/customInput";
import { onboardEmployeeNew } from "../../../../../redux/slices/auth/onboardnew/onboard";
import loader from "../../../../../asset/images/loader 2.gif";

interface MessageProps {
  formData: FormStepValues;
  setFormData: React.Dispatch<React.SetStateAction<FormStepValues>>;
  onSubmit: (data: FormStepValues) => Promise<any>;
  // onSubmit: () => void;
  onPreviousStep: () => void;
  errors: any;
}
const MessageNew = ({
  formData,
  setFormData,
  onSubmit,
  onPreviousStep,
  errors,
}: MessageProps) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    console.log({ formData });
    try {
      setIsLoading(true);
      const response = await dispatch(onboardEmployeeNew(formData));
      console.log({ response });
      if (response?.payload.statusCode === 200 || 201) {
        // onSubmit(formData);
        toast.success("Employee onboarded successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
        setIsLoading(false);
        navigate("/admin/onboarding");
      } else if (response?.payload.statusCode === 400) {
        toast.error("Employee already exists", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (response?.payload.statusCode === 500) {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    } catch (error: any) {
      setIsLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
          <img src={loader} alt="" width={800} height={800} />
        </div>
      )}
      <div className="pt-8 ">
        <div>
          <h3 className="pb-4 text-lg font-medium">Message</h3>
        </div>

        <form>
          <CustomInput
            id="message"
            label="Custom Message"
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            error={errors.message}
            className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            wrapperClassName=""
            placeholder=""
          />
          <CustomInput
            id="details"
            label="Send login details"
            type="checkbox"
            name="details"
            value={formData.details}
            onChange={handleChange}
            error={errors.details}
            className="pr-2 "
            wrapperClassName=""
            placeholder=""
          />
          <div className="h-[1px] bg-gray-300 mt-5 "></div>
          <div className="flex justify-between py-9">
            <button
              type="button"
              onClick={onPreviousStep}
              className="border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left"
            >
              Previous
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MessageNew;

import React from "react";
import CustomInput from "../../../../../utilities/forms/customInput";

interface AdminAccessProps {
  selectedAdminRights: string[];
  setSelectedAdminRights: (rights: string[]) => void;
}

function AdminAccess({
  selectedAdminRights,
  setSelectedAdminRights,
}: AdminAccessProps) {
  const adminRights = [
    "HR Database",
    "Expense Management",
    "Compensation and Benefit",
    "Performance Management",
    "Project Management",
  ];

  return (
    <div className="pt-8">
      <h2 className="text-xl font-medium">Admin Access</h2>
      <div className="my-4 ">
        <div className="pl-4 my-3 pt-4 w-[300px] rounded shadow-lg py-2 ">
          {adminRights.map((right, index) => (
            <CustomInput
              key={index}
              placeholder=""
              wrapperClassName=""
              type="checkbox"
              id={right}
              label={right}
              name={right}
              value={selectedAdminRights.includes(right)}
              onChange={() => {
                const newRightsArray = selectedAdminRights.includes(right)
                  ? selectedAdminRights.filter((item) => item !== right)
                  : [...selectedAdminRights, right];
                setSelectedAdminRights(newRightsArray);
              }}
              className="mr-3 border-[#D0D5DD]"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminAccess;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Country } from "../../../utilities/types/types";
import Service_API from "../../../api/service";

interface LegalEntityState {
  legalEntity: Country[];
}

export const getLegalEntity = createAsyncThunk("Legal-Entity", async () => {
  try {
    const response = await Service_API.getAllLegalEntity();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const legalEntitySlice = createSlice({
  name: "Legal-Entity",
  initialState: {
    legalEntity: [],
  } as LegalEntityState,
  reducers: {
    setlegalEntity: (state, { payload }) => {
      state.legalEntity = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(legalEntity.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setlegalEntity } = legalEntitySlice.actions;

export default legalEntitySlice.reducer;
